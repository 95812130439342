import { Link } from "gatsby"
import React from "react"

import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Colors from "../components/colors"


const HeaderContainer = styled.header`
  background-color: ${Colors.Secondary};
  display: flex;
  justify-content: center;
  border-bottom: 2px solid ${Colors.Secondary};
`

const HeaderLink = styled(Link)`
  padding: ${rhythm(0.5)};
  font-weight: bold;

  color: white;
  background: ${Colors.Secondary};
  transition: 0.3s;

  text-decoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }

  &:hover {
    color: ${Colors.Secondary};
    background: white;
  }
`

const activeStyle = {
  "borderBottom": `5px solid white`
}

const Header = () => (
  <HeaderContainer>
    <HeaderLink to="/" activeStyle={activeStyle}>Home</HeaderLink>
    <HeaderLink to="/illusts" activeStyle={activeStyle}>Illusts</HeaderLink>
    <HeaderLink to="/history" activeStyle={activeStyle}>Timeline</HeaderLink>
    {/* <HeaderLink to="/creators">参加者</HeaderLink> */}
  </HeaderContainer>
)

export default Header

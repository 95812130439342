/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rhythm } from "../utils/typography"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Colors from "./colors"


const Main = styled.main`
　min-height: 100vh;
`

const Footer = styled.footer`
  padding-top: ${rhythm(1)};
  padding-bottom: ${rhythm(1)};
  color: white;
  background: ${Colors.Secondary};
`

const FooterContent = styled.p`
  margin: auto;
  padding: ${rhythm(1)};
  font-size: 14px;
  max-width: 600px;
`


const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer>
        <FooterContent>
          このWebサイト及びサイトに含まれるイラストは
          鷹宮リオンさんのファンボ・ファンガが有志で制作したものです。
          </FooterContent>
      </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Colors from "../components/colors"


const Page = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${Colors.Secondary};
`

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Page>
      <Image imgStyle={{ height: '100vh', width: '100%' }} />
    </Page>
  </Layout>
)

export default IndexPage

import React from "react"
import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Colors from "../components/colors"

import twitterThumb from "../images/thumb_twitter.png"
import teikaThumb from "../images/thumb_teika.png"

const Page = styled.div`
  margin: auto;
  max-width: 800px;
`

const Title = styled.h1`
  text-align: center;
  color: ${Colors.MainBlack};
`

const RecordContainer = styled.div`
  margin-top: ${rhythm(2)};
`

const StyledThumb = styled.div`
  min-width: 320px;
  max-width: 320px;

  @media screen and (max-width: 480px) {
    min-width: 160px;
    max-width: 160px;
  }

  min-height: 100%;
  max-height: 100%;
  background: gray;
  margin-right: ${rhythm(0.5)};
  overflow: hidden;
`

const StyledImg = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const StyledLink = styled.a`
  height: 100%;
  width: 100%;
  text-decoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`

const Thumb = ({ url, status, expand }) => {
  if (!url) {
    return (
      <StyledThumb expand={expand}>
        <StyledImg src={teikaThumb}></StyledImg>
      </StyledThumb>
    )
  } else if (url.startsWith("https://youtu.be/")) {
    const videoId = url.replace("https://youtu.be/", "");
    if (status === "unlisted") {
      return (
        <StyledThumb expand={expand}>
          <StyledImg src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`} ></StyledImg>
        </StyledThumb >
      )
    } else {
      return (
        <StyledThumb expand={expand}>
          <StyledLink href={url} target="_blank">
            <StyledImg src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`} ></StyledImg>
          </StyledLink>
        </StyledThumb >
      );
    }
  } else if (url.startsWith("https://twitter.com/")) {
    return (
      <StyledThumb expand={expand}>
        <StyledLink href={url} target="_blank">
          <StyledImg src={twitterThumb} ></StyledImg>
        </StyledLink>
      </StyledThumb>
    )
  } else {
    return (
      <StyledThumb expand={expand}>
        <StyledLink href={url} target="_blank">
          <StyledImg src={teikaThumb}></StyledImg>
        </StyledLink>
      </StyledThumb>
    )
  }
}

const StyledRecord = styled.div`
  margin-top: ${rhythm(0.5)};
  margin-bottom: ${rhythm(0.5)};
  height: ${(props) => props.expand ? 180 : 60}px;
  display: flex;
  overflow: hidden;
  box-shadow: 2px 2px 6px #dddddd;
  transition: height 0.2s;

  @media screen and (max-width: 480px) {
    height: ${(props) => props.expand ? 180 : 60}px;
    overflow-y: ${(props) => props.expand ? "auto" : ""};
  }
`

const InfoContainer = styled.div`
  overflow-y: ${(props) => props.expand ? "auto" : "hidden"};
  &::-webkit-scrollbar {
    display: none;
  }
`

const RecordDate = styled.div`
  font-size: 14px;
  height: 24px;
  margin-top: 4px;
  border-radius: 12px;
  color: ${Colors.MainBlack};
`

const RecordTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-right: ${rhythm(0.5)};
  color: ${Colors.Secondary};
`

const RecordDescription = styled.div`
  font-size: 12px;
  padding-right: ${rhythm(0.5)};
`

const RecordTag = styled.div`
  font-size: 14px;
  color: ${Colors.Secondary};
`

const StyledA = styled.a`
  text-decoration: none;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`

const Record = ({ link, status, date, title, description, tag }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <StyledRecord
      expand={isExpanded}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <Thumb url={link} status={status} expand={isExpanded} />
      <InfoContainer expand={isExpanded}>
        <RecordDate>{date}</RecordDate>
        <RecordTitle>{title}</RecordTitle>
        <RecordDescription>{description}</RecordDescription>
        <RecordTag>{tag}</RecordTag>
      </InfoContainer>
    </StyledRecord>
  )
}

const HistoryPage = ({ data }) => (
  <Layout>
    <SEO title="年表" />
    <Page>
      <RecordContainer>
        {data.allGoogleSheetNenpyoRow.edges.map(({ node }) => (
          <Record
            key={node.id}
            link={node.link}
            status={node.videostatus}
            date={node.date}
            title={node.title}
            description={node.description}
            tag={node.tag}
          />
        ))}
      </RecordContainer>
    </Page>
  </Layout>
)

export const query = graphql`
  {
    allGoogleSheetNenpyoRow(sort: {fields: date}) {
      edges {
        node {
          date
          description
          id
          link
          tag
          title
          videostatus
        }
      }
    }
  }
`

export default HistoryPage
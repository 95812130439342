import Typography from "typography"

const typography = new Typography({
    baseFontSize: "18px",
    baseLineHeight: 1.666,
    headerFontFamily: [
        "Noto Sans JP",
    ],
    bodyFontFamily: ["Noto Sans JP"],
})

export const { scale, rhythm, options } = typography
export default typography

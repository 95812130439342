import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Colors from "../components/colors"


const Page = styled.div`
  margin: auto;
  max-width: 800px;
`

const Title = styled.h1`
  text-align: center;
  color: ${Colors.MainBlack};
`

const CreatorsContainer = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
`

const Creator = styled.div`
  margin: ${rhythm(0.5)};
`

const CreatorsPage = () => (
    <Layout>
        <SEO title="参加者" />
        <Page>
            <Title>参加者リスト</Title>
            <CreatorsContainer>
                <Creator>ああああ</Creator>
                <Creator>あああああ</Creator>
                <Creator>ああああ</Creator>
                <Creator>あああ</Creator>
                <Creator>ああああ</Creator>
                <Creator>ああああああああああ</Creator>
                <Creator>あああ</Creator>
                <Creator>ああああ</Creator>
            </CreatorsContainer>
        </Page>
    </Layout>
)

export default CreatorsPage
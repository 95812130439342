import React from "react"
import { useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import styled from "styled-components"
import { rhythm } from "../utils/typography"

import Colors from "../components/colors"

library.add(fab)

let Viewer;
if (typeof window !== `undefined`) {
    Viewer = require("react-viewer").default
}

const Page = styled.div`
  margin: auto;
  max-width: 1200px;
`

const Title = styled.h1`
  text-align: center;
  color: ${Colors.MainBlack};
`

const IllustContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Container = styled.div`
    padding: ${rhythm(0.5)};
    width: 200px;
`

const StyledName = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: ${Colors.Secondary};
`

const StyledA = styled.a`
  text-decoration: none;
  color: ${Colors.Secondary};
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`

const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: ${rhythm(0.2)};
`

const Name = ({ link, name }) => {
    return (
        <StyledName>
            <StyledA href={link} target="_blank">
                <StyledIcon icon={['fab', 'twitter']} />
                {name}
            </StyledA>
        </StyledName>
    )
}

const ImageContainer = styled.div`
    height: 300px;
    border-bottom: 2px solid ${Colors.Secondary};
`
const Image = styled(Img)`
    height: 100%;
    width: 100%;
    &:hover {
        cursor: pointer;
        transform: translateY(-5%);
        transition: transform 0.2s;
    }
`

const Illust = ({ name, filename, link, transform, data }) => {
    const [visible, setVisible] = React.useState(false);

    const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename)
    })
    const imageSizes = image.node.childImageSharp.sizes

    const [viewer, setViewer] = React.useState(null);

    React.useEffect(() => {
        setViewer(<Viewer
            visible={visible}
            onClose={() => { setVisible(false); }}
            onMaskClick={() => { setVisible(false); }}
            noToolbar
            noFooter
            images={[{ src: imageSizes.src, alt: '' }]}
        />)
    }, [visible])

    return (
        <Container>
            <ImageContainer onClick={() => { setVisible(true); }}>
                <Image sizes={imageSizes} imgStyle={{ objectFit: 'contain', transform: transform }} fadeIn />
            </ImageContainer>
            <Name link={link} name={name} />
            {viewer}
        </Container>
    )
}

const IllustsPage = ({ data }) => {
    return (
        <Layout>
            <SEO title="イラスト" />
            <Page>
                <IllustContainer>
                    {data.allGoogleSheetIllustRow.edges.map(({ node }) => (
                        <Illust
                            key={node.id}
                            name={node.name}
                            filename={node.filename}
                            link={node.link}
                            data={data}
                            transform={node.transform}
                        />
                    ))}
                </IllustContainer>
            </Page>
        </Layout>
    )
}

export const query = graphql`
  {
    allGoogleSheetIllustRow(sort: {fields: name}) {
      edges {
        node {
          name
          filename
          id
          link
          transform
        }
      }
    }
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxHeight: 1080) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`

export default IllustsPage